import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { ConferencesPageQuery } from "../../../graphql/types"
import { PageProps } from "@src-types/page"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { mapSourceFeaturedConferenceToView } from "@features/conferences/mappers/featured-conference-mapper"
import { ConferencesPage } from "@features/conferences/components/conferences-page/conferences-page"
import { Page } from "@components/page/page"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { conferenceIndexConfig } from "@features/conferences/conference-index-config"
import { defaultMetadata, PageOpenGraphImage } from "@core/constants"

export const Conferences: FunctionComponent<
  PageProps<ConferencesPageQuery, null>
> = ({ data, ...props }) => {
  const { header, metadata } = useIndexPageData("conferences")
  const featuredConference = mapSourceFeaturedConferenceToView(data!)
  const indexPageData = data?.contentfulIndexPage

  const metadataOverrides = {
    title:
      indexPageData?.metadata?.title || metadata.title || defaultMetadata.title,
    description:
      indexPageData?.metadata?.description ||
      metadata.description ||
      defaultMetadata.description,
    openGraphImage:
      indexPageData?.metadata?.image?.fixed?.src ||
      PageOpenGraphImage.Conferences ||
      defaultMetadata.openGraphImage
  }

  return (
    <Page
      {...props}
      metadata={{
        ...metadata,
        ...metadataOverrides
      }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.Conferences,
        defaultSearchIndex: conferenceIndexConfig.defaultSearchIndex
      }}
    >
      <ConferencesPage
        pageHeader={header}
        featuredConference={featuredConference}
      />
    </Page>
  )
}

export default Conferences

export const query = graphql`
  query ConferencesPage {
    contentfulIndexPage(key: { eq: "conferences" }) {
      ...FeaturedConference
    }
  }
`
