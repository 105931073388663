import React, { FunctionComponent, Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { FeaturedConference as FeaturedConferenceType } from "../../conference-types"
import { Avatar } from "../../../../components/avatar/avatar"
import { Button } from "../../../../components/button/button"
import { AvatarList } from "../../../../components/avatar/avatar-list"
import { PathPrefix } from "../../../../core/constants"
import { Link } from "../../../../components/link/link"
import styles from "./featured-conference.module.scss"

export interface FeaturedConferenceProps {
  conference: FeaturedConferenceType
}

export const FeaturedConference: FunctionComponent<FeaturedConferenceProps> = ({
  conference
}) => (
  <section className={styles.featuredConference}>
    <div className={styles.inner}>
      <div className={styles.image}>
        {conference.image && (
          <img data-src={conference.image} alt="" className="lazyload" />
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.label}>
          <FormattedMessage id="featured" />
        </div>
        <h2 className={styles.name}>{conference.name}</h2>
        {conference.teachers?.length && (
          <Fragment>
            {conference.teachers.length === 1 ? (
              <Link
                to={conference.teachers[0].link}
                className={styles.singleTeacher}
              >
                <Avatar image={conference.teachers[0].image} />
                <p>{conference.teachers[0].name}</p>
              </Link>
            ) : (
              <AvatarList avatars={conference.teachers} limit={5} />
            )}
          </Fragment>
        )}
        {!!conference?.messages && (
          <div className={styles.messages}>{conference?.messages} Messages</div>
        )}
        <Button
          to={`${PathPrefix.Conferences}/${conference.slug}`}
          variant="primary"
          className={styles.button}
        >
          Start watching
        </Button>
      </div>
    </div>
  </section>
)
