import React, { FunctionComponent, Fragment } from "react"
import { FeaturedConference as FeaturedConferenceType } from "../../conference-types"
import { IndexPageHeader } from "../../../../types/page"
import { IndexHeader } from "../../../../components/index-header/index-header"
import { FeaturedConference } from "../featured-conference/featured-conference"
import { SearchResults } from "../../../search/components/search-results/search-results"
import { conferenceIndexConfig } from "../../conference-index-config"
import { defaultSearchIndexes } from "../../../../features/search/search-constants"

export interface ConferencesPageProps {
  pageHeader: IndexPageHeader
  featuredConference: FeaturedConferenceType | null
}

const indexes = defaultSearchIndexes.filter(
  (x) => x.id !== "price_asc" && x.id !== "price_desc" && x.id !== "featured"
)

const { filters, searchConfig } = conferenceIndexConfig

export const ConferencesPage: FunctionComponent<ConferencesPageProps> = ({
  pageHeader,
  featuredConference
}) => (
  <Fragment>
    <IndexHeader header={pageHeader} />
    {featuredConference && (
      <FeaturedConference conference={featuredConference} />
    )}
    <SearchResults
      filters={filters}
      indexes={indexes}
      config={searchConfig}
      variant="list"
    />
  </Fragment>
)
