import {
  ConferencesPageQuery,
  ContentfulTeacher
} from "../../../../graphql/types"
import { FeaturedConference } from "../conference-types"
import { mapTeachers } from "../../../core/mappers/map-teachers"

export const mapSourceFeaturedConferenceToView = (
  queryResult: ConferencesPageQuery
): FeaturedConference | null => {
  const data = queryResult.contentfulIndexPage

  if (!data?.featuredResources?.length) {
    return null
  }

  const featuredConference = data.featuredResources[0]

  if (!featuredConference?.name || !featuredConference.slug) {
    return null
  }

  return {
    name: featuredConference.name!,
    image: featuredConference.image?.fluid?.src || "",
    teachers: featuredConference.teachers
      ? mapTeachers(featuredConference.teachers as Partial<ContentfulTeacher>[])
      : [],
    slug: featuredConference.slug!,
    messages: featuredConference?.mediaResources?.length || 0
  }
}
