import { IndexPageConfig } from "../../../gatsby/config/create-pages/types"
import { PathPrefix } from "../../core/constants"

export const conferenceIndexConfig: IndexPageConfig = {
  templatePath: "./src/features/conferences/templates/conferences-template.tsx",
  pagePath: PathPrefix.Conferences,
  searchConfig: {
    filters: `type:"Conference Messages"`
  },
  attributes: ["type", "time", "title", "url"],
  filters: [
    {
      attribute: "fields.primaryTopic.fields.name",
      title: "search-filter-topics"
    },
    {
      attribute: "fields.teachers.fields.shortName",
      title: "search-filter-teachers"
    },
    {
      attribute: "fields.primaryScriptureReference.start.book",
      title: "search-filter-scripture"
    }
  ],
  defaultSearchIndex: "newest"
}
